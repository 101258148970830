<template>
  <div class="home" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="home-con">
        <div v-if="signInStatus">
            <div class="centent">
                <div class="messageOne">
                    <span class="courseName" v-if="courseName">第{{classNum}}课次</span>
                    <span v-if="courseName">({{courseStartTime}} - {{courseEndTime}})</span>
                    <span v-if="!courseName">{{noCourseMessage}}</span>
                </div>
                <div class="messageTwo">{{messageTipForTeacher}}</div>
                <div class="messageThree" :style="message === '迟到' ? 'color: #EE0728' : 'color: #393939'">{{message}}</div>
                <div class="time">
                    <span :class="timeStatus ? 'transTime' : 'transTimeToo'">{{hour}}</span>
                    <span :class="timeStatus ? 'spot' : 'spotToo'">:</span>
                    <span :class="timeStatus ? 'transTime' : 'transTimeToo'">{{min}}</span>
                    <span :class="timeStatus ? 'spot' : 'spotToo'">:</span>
                    <span :class="timeStatus ? 'transTime' : 'transTimeToo'">{{sec}}</span>
                </div>
                <div class="btn">
                    <el-button :loading="loading" :class="timeOut ? 'canSignIn' : 'cantSignIn'" :disabled="timeOut ? false : true" @click="signIn">
                        <span v-if="!loading">{{timeOut ? '立即签到' : '暂不可签到'}}</span>
                        <span v-else>签 到 中...</span>
                    </el-button>
                </div>
                <div class="messageFour">{{tipMessage}}</div>
            </div>
        </div>
        <div v-if="!signInStatus">
            <div class="signInSuc">
                <div class="sucIcon"></div>
                <div class="sucTip">
                    <div class="sucTipMessage">
                         {{sucTipMessage}}
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { page } from './../../api/index.js';
import { Message } from 'element-ui';
export default {
  name: "home",
  
  data() {
    return {
        message: '距离上课时间还有',
        tipMessage: '可提前15分钟签到',
        sucTipMessage: '教师签到已完成，即将进入班级学员考勤',
        courseStartTime: '',
        courseEndTime: '',
        courseName: '',
        messageTipForTeacher: '课次或上课时间与实际不同时请联系学管师',
        noCourseMessage: '今日无课程！',
        hour: '--',
        min: '--',
        sec: '--',
        loading: false,
        timeOut: false,
        timer: null,
        signInStatus: true,
        supplyTeacherId: '',
        fullscreenLoading: false,
        timeStatus: true,
        classNum: ''
    };
  },
 
   async created() {
       await this.getCoursePaikeId();
       this.getPage();
        // this.getPaikeId().then(() => {
        //     this.getPage();
        // }).catch(() => {
        //     this.getPaikeIdForCurrDayLatestClass().then(() => {
        //         this.getPage();
        //     }).catch(() => {});;
        // })
  },
  computed:{
      docmentVisibility() {
        return this.$store.state.user.docmentVisibility;
      },
  },
  watch: {
    docmentVisibility(newVal) {
        if (!newVal) return;
        if(this.timer) {
            clearInterval(this.timer); 
        };
        this.getCoursePaikeId();
        // this.getPaikeId().then(() => {
        //     this.getPage();
        // }).catch(() => {
        //     this.getPaikeIdForCurrDayLatestClass().then(() => {
        //         this.getPage();
        //     }).catch(() => {});
        // })
    },
  },
  destroyed(){
        if(this.timer) { //如果定时器在运行则关闭
            clearInterval(this.timer); 
        }
  },
  methods: {
        async getCoursePaikeId(){
            this.fullscreenLoading = true;
            const that = this;
            await page.getCoursePaikeId('', res => {
                if(res.status === 1) {
                    if(res.data && res.data.courseName){
                        if(!res.data.scheduleId){
                            Message.error(`排课ID获取失败，请不要操作，并联系管理员！ + ${JSON.stringify(res.data)}`);
                        }
                        that.paikeId = res.data.scheduleId;
                        that.classNum = res.data.classNum;
                        that.supplyTeacherId = res.data.supplyTeacherId;
                        let num = res.data.remainTime;
                        that.courseStartTime = res.data.courseStartTime;
                        that.courseEndTime = res.data.courseEndTime;
                        that.courseName = res.data.courseName;
                        that.$store.commit('SETCLASSCODE', res.data.classCode);
                        localStorage.classCode = res.data.classCode;
                        that.setIntervalForNum(num);
                    }else{
                        that.supplyTeacherId = '';
                        that.courseStartTime = '';
                        that.courseEndTime = '';
                        that.courseName = '';
                        that.classNum = '';
                        }
                }else {
                    Message.error(res.message);
                }
                that.fullscreenLoading = false;
            });
        },
        // getPaikeId(){
        //     this.fullscreenLoading = true;
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         page.getCurrDayOnClass({}, res => {
        //             if(res.status === 1) {
        //                 if(res.data && res.data.courseName){
        //                     that.paikeId = res.data.paikeId;
        //                     that.classNum = res.data.classNum;
        //                     that.supplyTeacherId = res.data.supplyTeacherId;
        //                     let num = res.data.remainTime;
        //                     that.courseStartTime = res.data.courseStartTime;
        //                     that.courseEndTime = res.data.courseEndTime;
        //                     that.courseName = res.data.courseName;
        //                     that.$store.commit('SETCLASSCODE', res.data.classCode);
        //                     localStorage.classCode = res.data.classCode;
        //                     that.setIntervalForNum(num);
        //                     that.fullscreenLoading = false;
        //                     resolve()
        //                 }else{
        //                     that.fullscreenLoading = false;
        //                     reject()
        //                 }   
        //             }else {
        //                 that.fullscreenLoading = false;
        //                 Message.error(res.message);
        //             }
        //         });
        //     })
        // },
        // getPaikeIdForCurrDayLatestClass(){
        //     this.fullscreenLoading = true;
        //     const that = this;
        //     return new Promise(function(resolve,reject){
        //         page.getCurrDayLatestClassTime({}, res => {
        //             if(res.status === 1) {
        //                 if(res.data && res.data.courseName){
        //                     that.paikeId = res.data.paikeId;
        //                     that.classNum = res.data.classNum;
        //                     that.supplyTeacherId = res.data.supplyTeacherId;
        //                     let num = res.data.remainTime;
        //                     that.courseStartTime = res.data.courseStartTime;
        //                     that.courseEndTime = res.data.courseEndTime;
        //                     that.courseName = res.data.courseName;
        //                     that.$store.commit('SETCLASSCODE', res.data.classCode);
        //                     localStorage.classCode = res.data.classCode;
        //                     that.setIntervalForNum(num);
        //                     resolve()
        //                 }else{
        //                     that.supplyTeacherId = '';
        //                     that.courseStartTime = '';
        //                     that.courseEndTime = '';
        //                     that.courseName = '';
        //                     that.classNum = '';
        //                     reject()
        //                 }
        //             }else {
        //                 Message.error(res.message);
        //             }
        //             that.fullscreenLoading = false;
        //         });
        //     })
        // },
        caluTime (mss) {
            if(mss && mss >= 0){
                this.timeStatus = true;
                this.message = '距离上课时间还有';
                this.tipMessage = '可提前15分钟签到';
                this.hour = parseInt(mss / (1000 * 60 * 60));
                this.min = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
                this.sec = Math.round((mss % (1000 * 60)) / 1000);
                this.timeOut = this.min < 15 && this.hour === 0 ? true : false;
                if (this.hour < 10) {
                    this.hour = '0' + this.hour
                }
                if (this.min < 10) {
                    this.min = '0' + this.min
                }
                if (this.sec < 10) {
                    this.sec = '0' + this.sec
                }
            }else if(mss < 0){
                this.timeStatus = false;
                this.message = '迟到';
                let mssAbs = Math.abs(mss);
                this.hour = parseInt(mssAbs / (1000 * 60 * 60));
                this.min = parseInt((mssAbs % (1000 * 60 * 60)) / (1000 * 60));
                this.sec = Math.round((mssAbs % (1000 * 60)) / 1000);
                this.timeOut = true;
                if (this.hour < 10) {
                    this.hour = '0' + this.hour
                }
                if (this.min < 10) {
                    this.min = '0' + this.min
                }
                if (this.sec < 10) {
                    this.sec = '0' + this.sec
                }
            }else{
                this.timeStatus = true;
                this.message = '距离上课时间还有';
                this.tipMessage = '可提前15分钟签到';
                this.hour = '--';
                this.min = '--';
                this.sec = '--';
                this.timeOut = false;
                clearInterval(this.timer); 
            }
         },
        getPage(){
            if(this.paikeId){
                let id = this.paikeId  ? this.paikeId : '0';
                page.goToPage(id, res => {
                    if(res.status === 1) {
                        if(res.data === 'class'){
                            this.$router.replace({name: 'attendClass'})
                        }else if(res.data === 'signIn'){
                            this.$router.replace({name: 'checkWork'})
                        }
                    }else {
                        Message.error(res.message);
                    }
                });
            }
        },
        setIntervalForNum(num){
            clearInterval(this.timer); 
            if(num > 1000){
                this.timer = setInterval(() => {
                    num=num-1000 
                    this.caluTime(num)
                }, 1000);
            }else if(num < 0){
                this.timer = setInterval(() => {
                    num=num - 1000 
                    this.caluTime(num)
                }, 1000);
            }else{
                this.caluTime(num)
            }
        },
        signIn(){
            this.loading = true;
            const params = {
                schedulingId: this.paikeId,
                teacherId: this.supplyTeacherId,
            }
            page.teacherSignIn(params, res => {
                if(res.status === 1) {
                    this.signInStatus = false
                    this.loading = false;
                    setTimeout(() => {
                        clearInterval(this.timer); 
                        this.$router.replace({name: 'checkWork'})
                    }, 1500);
                }else {
                    this.loading = false;
                    Message.error(res.message + this.paikeId);
                }
            });
        },

  },
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  width: 100%;
  position: absolute;
  background: url("../../assets/image/bg-login.png") center/cover no-repeat;
  .home-con {
    position: fixed;
    left: 50%;
    top: 48%;
    width: 900px;
    height: 560px;
    background: url("../../assets/image/bg-login-card.png") center/cover no-repeat;
    transform: translateY(-50%) translateX(-50%);
    .tips{
        position: absolute;
        left: 50%;
        top: 2%;
        width: 400px;
        height: 110px;
        background: url("../../assets/image/tip.png") center/cover no-repeat;
        transform: translateY(-50%) translateX(-50%);
    }
    .centent{
        position: absolute;
        left: 60px;
        top: 35px;
        width: 780px;
        height: 490px;
        background: #FFFCFD;
        border-radius: 170px;
        .messageOne{
            text-align: center;
            line-height: 40px;
            font-size: 20px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #444343;
            margin-top: 20px;
            font-family: Noto Sans S Chinese;
            margin-bottom: 10px;
            .courseName{
                display: inline-block;
                margin-right: 10px;
            }
        }
        .messageTwo{
            text-align: center;
            line-height: 30px;
            font-size: 24px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #2CBD74;
            letter-spacing: 4px;
        }
        .messageThree{
            text-align: center;
            line-height: 70px;
            font-size: 24px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #2CBD74;
        }
        .messageFour{
            text-align: center;
            line-height: 70px;
            font-size: 20px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #939393;
        }
        .btn{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
        }
        .time{
            display: grid;
            grid-template-columns: 90px 40px 90px 40px 90px;
            grid-template-rows: 100px;
            width: 100%;
            height: 100px;
            margin-top: 10px;
            justify-content: center;
            .transTime{
                display: inline-block;
                width: 90px;
                height: 100px;
                background: #F9E270;
                border-radius: 20px;
                font-size: 36px;
                font-family: Noto Sans S Chinese;
                font-weight: 400;
                color: #42D78C;
                line-height: 100px;
                text-align: center;
            }
            .transTimeToo{
                display: inline-block;
                width: 90px;
                height: 100px;
                background: #F9E270;
                border-radius: 20px;
                font-size: 36px;
                font-family: Noto Sans S Chinese;
                font-weight: 400;
                color: red;
                line-height: 100px;
                text-align: center;
            }
            .spot{
                box-sizing:content-box;
                display: inline-block;
                height: 100px;
                width: 40px;
                font-size: 36px;
                font-family: Noto Sans S Chinese;
                font-weight: 400;
                color: #42D78C;
                line-height: 100px;
                text-align: center;
            }
            .spotToo{
                box-sizing:content-box;
                display: inline-block;
                height: 100px;
                width: 40px;
                font-size: 36px;
                font-family: Noto Sans S Chinese;
                font-weight: 400;
                color: red;
                line-height: 100px;
                text-align: center;
            }
        }
        .canSignIn{
            margin-top: 10px;
            font-size: 30px;
            color: #ffffff;
            width: 540px;
            height: 90px;
            padding: 0;
            border-radius: 48px;
            background: url("../../assets/image/btn-login.png") center -5px no-repeat;
            background-size: cover;
            border: none;
        }
        .cantSignIn{
            margin-top: 10px;
            font-size: 30px;
            color: #ffffff;
            width: 540px;
            height: 90px;
            padding: 0;
            border-radius: 48px;
            background: url("../../assets/image/disBtn.png") center -5px no-repeat;
            background-size: cover;
            border: none;
        }
    }
    .signInSuc{
        position: absolute;
        left: 60px;
        top: 35px;
        width: 780px;
        height: 490px;
        background: #FFFCFD;
        border-radius: 170px;
        .sucIcon{
            position: absolute;
            left: 50%;
            top: 32%;
            width: 280px;
            height: 250px;
            background: url("../../assets/image/signIn-suc.png") center/cover no-repeat;
            transform: translateY(-50%) translateX(-50%);
        }
        .sucTip{
            position: absolute;
            top: 70%;
            width: 100%;
            .sucTipMessage{
                text-align: center;
                line-height: 70px;
                font-size: 30px;
                font-family: Noto Sans S Chinese;
                font-weight: 400;
                color: #939393;
            }
        }
    }
  }
}
</style>
